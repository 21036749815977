import React, { Component } from 'react'
import ApiManager from '../../api/ApiManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-before-after-slider'
import style from './StylistPage.module.scss'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import saveAs from 'file-saver'
import ShareButtons from '../../../../components/shareButtons/ShareButtons'
import layout from '../../DemoSingeLayout.module.scss'

class StylistPage extends Component {
  value = ''
  imgURL = ''
  imgURL1 = ''
  imgURL2 = ''
  slider = false
  apiHost = 'stylist?'
  selectedHdf = 'scream'
  /*   imgType = '' */
  static propTypes = {
    uploadText: PropTypes.string,
    downloadBtn: PropTypes.string,
    samplesHeading: PropTypes.string,
  }

  static defaultProps = {
    uploadText: 'Для загрузки, перетащите файл сюда.',
    downloadBtn: 'Download',
    samplesHeading: 'Выберите стиль',
  }

  addLoader = () => {
    if (this.loader) {
      this.loader.classList.add(layout.loaderActive)
      this.loader.scrollIntoView({ behavior: 'smooth' })
    }
  }

  removeLoader = () => {
    if (this.loader) this.loader.classList.remove(layout.loaderActive)
  }

  chooseImage = el => {
    if (el.target.files.length > 0) {
      this.uploadImage(el.target.files[0])
    }
    this.forceUpdate()
  }

  uploadImage = imageData => {
    /* this.imgType = imageData.type.split('/').pop() */
    const imageUrl = window.URL.createObjectURL(imageData)
    this.setFullImage1(imageUrl)
    this.addLoader()
    ApiManager.sendRequestForPic(
      this.apiHost,
      ApiManager.parseStylist,
      source => {
        this.removeLoader()
        this.setFullImage2(source)
      },
      { picUrl: imageUrl, hdf: this.selectedHdf },
      imageData
    )
  }

  uploadUrl = imageUrl => {
    this.setFullImage1(imageUrl)
    this.addLoader()
    ApiManager.sendRequestForPic(
      this.apiHost,
      ApiManager.parseStylist,
      source => {
        this.removeLoader()
        this.setFullImage2(source)
      },
      { picUrl: imageUrl, hdf: this.selectedHdf }
    )
  }

  setFullImage1(source) {
    this.imgURL = source
    this.imgURL1 = source
    this.imgURL2 = source
    this.forceUpdate()
  }

  setFullImage2(source) {
    this.imgURL2 = source
    this.forceUpdate()
  }

  setFullImage(source) {
    this.imgURL = source
    this.forceUpdate()
  }

  getFileName = url => {
    if (url) {
      const m = url.toString().match(/.*\/(.+?)\./)
      if (m && m.length > 1) {
        return m[1]
      }
    }
    return ''
  }

  resend(source) {
    /*   this.imgType = 'jpg' */

    this.selectedHdf = this.getFileName(source)
    if (this.imgURL2 !== '') {
      const dataUrl = this.imgURL1
      if (dataUrl.slice(0, 4) === 'file') {
        this.uploadImage(dataUrl)
        this.forceUpdate()
      } else if (dataUrl.slice(0, 4) === 'http') {
        this.uploadUrl(dataUrl)
        this.forceUpdate()
      } else {
        let xhr = new XMLHttpRequest()
        xhr.open('GET', dataUrl, true)
        xhr.responseType = 'blob'
        xhr.onload = e => {
          if (e.target.status === 200) {
            const myBlob = e.target.response
            this.uploadImage(myBlob)
          }
        }
        xhr.send()
        this.forceUpdate()
      }
    } else {
      this.setFullImage1(source)
      this.forceUpdate()
    }
  }

  onDrop = event => {
    this.hover = false
    event.preventDefault()
    if (event.dataTransfer.files.length === 0) {
      const url = event.dataTransfer.getData('text/uri-list')
      this.uploadUrl(url)
    } else {
      const file = event.dataTransfer.files[0]
      this.uploadImage(file)
    }
  }

  onClickDownload = () => {
    saveAs(this.imgURL2, `demo.png`)
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={layout.loader}
          ref={loader => {
            this.loader = loader
          }}>
          <i></i>
          <span>Идёт распознавание...</span>
        </div>
        <div
          onDragOver={e => {
            this.hover = true
            e.preventDefault()
            return false
          }}
          onDragLeave={() => {
            this.hover = false
            return false
          }}
          onDrop={this.onDrop}
        >
          <div>
            <div className={style.sliderContainer} style={{ display: this.imgURL1 === '' ? 'none' : 'flex' }}>
              <Slider before={this.imgURL1} after={this.imgURL2} width={640} height={480} />
            </div>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={input => {
                this.aInput = input
              }}
              onChange={el => {
                if (el.target.files.length > 0) {
                  this.uploadImage(el.target.files[0])
                }
                this.forceUpdate()
              }}
            />
            <div
              onClick={() => {
                this.aInput.click()
              }}
              className={classnames(style.uploadContainer, style.uploadBg)}
              style={{ display: this.imgURL1 !== '' ? 'none' : 'flex' }}
            >
              <FontAwesomeIcon size="10x" icon={['fas', 'cloud-upload-alt']} />
              <p dangerouslySetInnerHTML={{ __html: this.props.uploadText }} />
            </div>
          </div>
        </div>
        <div style={{ display: this.imgURL2 == '' ? 'none' : this.imgURL1 == this.imgURL2 ? 'none' : 'block' }}>
          <button className={style.downloadBtn} onClick={this.onClickDownload}>
            <FontAwesomeIcon size="lg" icon={['fas', 'download']} />
            <p dangerouslySetInnerHTML={{ __html: this.props.downloadBtn }} />
          </button>
          <ShareButtons />
        </div>
        <div>
          <h4 className={style.samplesHeading} dangerouslySetInnerHTML={{ __html: this.props.samplesHeading }} />
          <div className={style.samplesImages}>
            <img
              alt=""
              onClick={() => {
                this.resend('../../../../../assets/images/newashmanovnet/demo/stylist/mosaic_medium.jpg')
              }}
              src={'../../../../../assets/images/newashmanovnet/demo/stylist/mosaic_medium.jpg'}
            />
            <img
              alt=""
              onClick={() => {
                this.resend('../../../../../assets/images/newashmanovnet/demo/stylist/one_punch_man_medium_1.jpg')
              }}
              src={'../../../../../assets/images/newashmanovnet/demo/stylist/one_punch_man_medium_1.jpg'}
            />
            <img
              alt=""
              onClick={() => {
                this.resend('../../../../../assets/images/newashmanovnet/demo/stylist/olive_tree_medium.jpg')
              }}
              src={'../../../../../assets/images/newashmanovnet/demo/stylist/olive_tree_medium.jpg'}
            />
            <img
              alt=""
              onClick={() => {
                this.resend('../../../../../assets/images/newashmanovnet/demo/stylist/mona_lisa_medium.jpg')
              }}
              src={'../../../../../assets/images/newashmanovnet/demo/stylist/mona_lisa_medium.jpg'}
            />
            <img
              alt=""
              onClick={() => {
                this.resend('../../../../../assets/images/newashmanovnet/demo/stylist/scream.jpg')
              }}
              src={'../../../../../assets/images/newashmanovnet/demo/stylist/scream.jpg'}
            />
            <img
              alt=""
              onClick={() => {
                this.resend('../../../../../assets/images/newashmanovnet/demo/stylist/wave.jpg')
              }}
              src={'../../../../../assets/images/newashmanovnet/demo/stylist/wave.jpg'}
            />
          </div>
        </div>
        <div className={style.addImageContainer}>
          <FontAwesomeIcon
            className={style.addImageBottom}
            size="3x"
            icon={['far', 'plus-square']}
            onClick={() => {
              this.aInput.click()
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default StylistPage
