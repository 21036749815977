import React, { PureComponent } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  VKShareButton,
} from 'react-share'

import style from './ShareButtons.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Location } from '@reach/router'

class ShareButtons extends PureComponent {
  render() {
    return (
      <Location>
        {({ location }) => {
          return (
            <div className={style.shareContainer}>
              <FacebookShareButton url={location.href}>
                <FontAwesomeIcon className={style.iconContainer} size="2x" icon={['fab', 'facebook']} />
              </FacebookShareButton>
              <TwitterShareButton url={location.href}>
                <FontAwesomeIcon className={style.iconContainer} size="2x" icon={['fab', 'twitter']} />
              </TwitterShareButton>
              <TelegramShareButton url={location.href}>
                <FontAwesomeIcon className={style.iconContainer} size="2x" icon={['fab', 'telegram']} />
              </TelegramShareButton>
              <WhatsappShareButton url={location.href}>
                <FontAwesomeIcon className={style.iconContainer} size="2x" icon={['fab', 'whatsapp']} />
              </WhatsappShareButton>
              <VKShareButton url={location.href}>
                <FontAwesomeIcon className={style.iconContainer} size="2x" icon={['fab', 'vk']} />
              </VKShareButton>
            </div>
          )
        }}
      </Location>
    )
  }
}

export default ShareButtons
