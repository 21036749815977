import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import DefaultLayout from '../../layouts/default/defaultLayout'
import { withIntl } from '../../i18n'
import DemoSingleLayout from '../../layouts/demo/DemoSingleLayout'
import StylistPage from '../../layouts/demo/demos/stylist/StylistPage'

class DemoStylist extends React.Component {
  state = {
    title: this.props.intl.messages.demoPage.title,
    config: this.props.intl.messages.demoPage.demoStylist,
    btnBack: this.props.intl.messages.demoPage.btnBack,
    uploadText: this.props.intl.messages.demoPage.uploadText,
    downloadBtn: this.props.intl.messages.demoPage.demoStylist.downloadBtn,
    samplesHeading: this.props.intl.messages.demoPage.samplesHeading,
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { title, config, btnBack, uploadText, downloadBtn, samplesHeading } = this.state
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={title}
        />
        <DemoSingleLayout config={config} btnBack={btnBack}>
          <StylistPage samplesHeading={samplesHeading} downloadBtn={downloadBtn} uploadText={uploadText} />
        </DemoSingleLayout>
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(DemoStylist))
